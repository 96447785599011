import React from 'react';
import { Link } from 'react-router-dom';
import './Login.css'; // Impor file CSS untuk gaya tambahan
import { FcProcess, FcApproval, FcSelfie } from 'react-icons/fc';
import { RiLogoutCircleRFill } from "react-icons/ri";

function BottomNav() {
    const descriptionStyle = {
        backgroundColor: 'rgb(22 147 43)',
        fontWeight: 'bold',
    };
    const iconTextStyle = {
        fontSize: '14px', // Ukuran teks disesuaikan dengan kebutuhan
        textAlign: 'center', // Posisi teks diatur ke tengah
        marginTop: '5px', // Jarak antara ikon dan teks
    };
  return (
    <div className="fixed bottom-0 left-0 right-0 flex justify-around p-4 border-t border-gray-300 container-bottom" style={descriptionStyle}>
        <Link to="/onprogress" className="transition-transform duration-300 transform text-white-500 focus:outline-none hover:scale-105" style={{ fontFamily:'cursive', fontSize:15 }}>
            <FcProcess size={30} style={iconTextStyle} /> Onprogress
        </Link>
        <Link to="/completed" className="transition-transform duration-300 transform text-white-500 focus:outline-none hover:scale-105" style={{ fontFamily:'cursive', fontSize:15 }}>
            <FcApproval size={30} style={iconTextStyle} /> Completed
        </Link>
        <Link to="/mustahik" className="transition-transform duration-300 transform text-white-500 focus:outline-none hover:scale-105" style={{ fontFamily:'cursive', fontSize:15 }}>
            <FcSelfie size={30} style={iconTextStyle} /> Mustahik
        </Link>
        <Link to="/" className="transition-transform duration-300 transform text-white-500 focus:outline-none hover:scale-105" style={{ fontFamily:'cursive', fontSize:15 }}>
            <RiLogoutCircleRFill size={30} style={iconTextStyle} /> Logout
        </Link>
    </div>
  );
}

export default BottomNav;
