import { useState } from "react";
import { useZxing } from "react-zxing";

function Scanner(props) {
  const [result, setResult] = useState("");
  const { onDetected } = props;
  const { ref } = useZxing({
    onDecodeResult(decodedResult) {
      // console.log("kesini");
      // console.log("Decoded result:", decodedResult);
      onDetected(decodedResult.text);
      setResult(decodedResult.text);
    },
  });

  return (
    <>
      <video ref={ref} />
    </>
  );
};

export default Scanner;
