import axios from 'axios';
import React, { useRef, useState, useEffect } from 'react';
import Scanner from "./Scanner";
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import { Button, Card, Select, Label, TextInput, Spinner } from 'flowbite-react';
import BottomNav from './BottomNav';
import "./styles.css";

function Onprogress() {
    const videoRef = useRef(null);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [loading, setLoading] = useState(true);
    const [noawb, setNoawb] = useState(null);
    const [namacon, setNamacon] = useState(null);
    const [status, setStatus] = useState(null);
    const [dstid, setDstid] = useState(null);
    const [Iddst, setIddst] = useState(null);
    const [selectedValue, setSelectedValue] = useState('');
    const [keterangan, setKeterangan] = useState('');
    const [showVideo, setShowVideo] = useState(false);
    const [showVideox, setShowVideox] = useState(false);
    const [userData, setUserData] = useState(null);
    const [data, setData] = useState([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isDataSubmitted, setIsDataSubmitted] = useState(false);

    const [capturedImage, setCapturedImage] = useState(null);
    const [cameraIsActive, setCameraIsActive] = useState(false);

    const [camera, setCamera] = useState(false);
    const [result, setResult] = useState(null);

    const onDetected = result => {
      setResult(result);
      setNoawb(result);
    };

    const handleTakePhoto = (dataUri) => {
      setCapturedImage(dataUri);
      setCameraIsActive(false); // Matikan kamera setelah mengambil foto
    };

    const toggleCamera = () => {
      setCameraIsActive(!cameraIsActive);
      setShowVideox(true); // Set showVideo to true when the camera is active
    };

    const startCamera = async () => {
      setShowVideo(true);
      try {
          const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
          videoRef.current.srcObject = stream;
        } catch (error) {
          console.error('Error accessing the camera:', error);
          setShowVideo(false);
        }
    };

    const getData = async () => {
      try {
        const response = await axios.post('https://qurbanque.iconicbase.com/restapi/cekdata_web.php', {
          noawb: noawb
        });

        const data = response;
        console.log('data.length');
        console.log(data.data.total);
        if(data.data.data.f_status == '99999'){
          alert('status sudah complete');
          setDstid('');
          setIddst('');
          setStatus('');
          setNamacon('');
          setKeterangan('');
        }else{
          setDstid(data.data.total === 7 ? 'gabungan shohibul 1/7' : data.data.data.shohibul);
          setIddst(data.data.data.idhewan);
          setStatus(data.data.data.f_status);
          setNamacon(data.data.data.namahewan);
          setKeterangan('');
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    }

    const getShipment = async () => {
      try {
        const response = await axios.get('https://qurbanque.iconicbase.com/restapi/shipment_web_new.php');
        setData(response.data.data);
        console.log(response.data.data);
       
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };

    const handleSelectChange = (e) => {
      if(e.target.value > status){
        setSelectedValue(e.target.value);
      }else{
        alert('Maaf Status Tracking Salah');
      }
    };

    useEffect(() => {
      const storedUserData = JSON.parse(localStorage.getItem('userData'));
      if (storedUserData) {
        setUserData(storedUserData);
      }
      getShipment();
    }, []);

    useEffect(() => {
      setIsButtonDisabledPhoto(!(selectedValue === '1' || selectedValue === '2'));
    }, [selectedValue]);

    const simpanData = async () => {
      if(selectedValue > status ){
        setIsButtonDisabled(true); // Menonaktifkan tombol saat mengirim data
        try {
          const response = await axios.post('https://qurbanque.iconicbase.com/restapi/simpandata_web.php', {
            noawb: noawb,
            jenis_shipment: selectedValue,
            keterangan: keterangan,
            // id_shohibul:namacon,
            id_kota_tujuan: Iddst,
            inputby: userData,
            gambar: capturedImage,
          }, {
            headers: {
              'Content-Type': 'image/jpeg', // Sesuaikan dengan tipe gambar yang dikirim
            },
          });
          console.log(response.status);
          if(response.status === 200){
            alert('Data Berhasil Disimpan');
            setIsButtonDisabled(false);
            setIsDataSubmitted(true); // Mengatur status pengiriman data berhasil
            setNoawb('');
            setIddst('');
            setDstid('');
            setNamacon('');
            setSelectedValue('');
            setKeterangan('');
            setCapturedImage('');
          }else{
            alert('Data Gagal Disimpan');
            setNoawb('');
            setIddst('');
            setDstid('');
            setNamacon('');
            setSelectedValue('');
            setKeterangan('');
            setCapturedImage('');
          }
        } catch (error) {
          console.error('An error occurred:', error);
        } finally {
          setIsButtonDisabled(false); // Mengaktifkan tombol setelah pengiriman data selesai
        }
      }else{
        setCapturedImage('');
        alert('Maaf Status Tracking Salah');
      }
      
    };

    const descriptionStyle = {
      backgroundColor: 'rgb(22 147 43)',
      fontSize: '10px',
    };

    const isButtonDisabledx =
      noawb === '' ||
      selectedValue === '' ||
      keterangan === '' ||
      isButtonDisabled;

    const [isButtonDisabledPhoto, setIsButtonDisabledPhoto] = useState(true);

    useEffect(() => {
      setIsButtonDisabledPhoto(!(selectedValue === '1' || selectedValue === '2'));
    }, [selectedValue]);

    return (
      <div>
          <div className="p-2 mb-4 bg-white rounded-lg shadow-md" style={descriptionStyle} >
              <h2 className="mb-2 text-xl font-semibold">ONPROGRESS</h2>
              <p className="text-gray-600">
                  User Login : {userData}
              </p>
          </div>
          <div>
          <Card>
              <form className="flex flex-col gap-4">
                  <div>
                      <div className="block mb-2">
                          <Label htmlFor="noawb" value="ID Qurban" />
                      </div>
                      <TextInput
                          id="noawb"
                          value={noawb}
                          onChange={(e) => setNoawb(e.target.value)}
                          placeholder="Masukan No ID Qurban"
                          required
                          type="text"
                      />
                  </div>
                  <Button onClick={getData} style={{ fontFamily:'cursive' }}>Retrieve Data</Button>
                  <div className="App">
                    {camera && (
                      <div className="container">
                        {camera && <Scanner onDetected={onDetected} />}
                      </div>
                    )}
                  </div>
                  <Button style={{ fontFamily:'cursive' }} onClick={() => setCamera(!camera)}>{camera ? "Stop Scan Barcode" : "Mulai Scan Barcode"}</Button>
                  {showVideo && (
                      <div>
                          <video ref={videoRef} autoPlay playsInline />
                      </div>
                  )}
                  <div>
                      <div className="block mb-2">
                          <Label htmlFor="consignee" value="Shohibul" />
                      </div> 
                      <TextInput
                          id="kotatujuan"
                          value={dstid}
                          onChangeText={dstid => setDstid(dstid)}
                          placeholder="Masukan Shohibul"
                          type="text"
                          readOnly
                      />
                      <div className="block mb-2">
                          <Label htmlFor="kotatujuan" value="Jenis Qurban" />
                      </div>
                      <TextInput
                          id="consignee"
                          value={namacon}
                          placeholder="Masukan Jenis Qurban"
                          onChangeText={namacon => setNamacon(namacon)}
                          type="text"
                          readOnly
                      />
                      <div className="max-w-md" id="select">
                        <div className="block mb-2">
                          <Label htmlFor="countries" value="Jenis Tracking" />
                        </div>
                        <Select id="countries" required onChange={handleSelectChange} value={selectedValue}>
                          <option>Pilih Jenis Tracking</option>
                          {data.map((option) => (
                            <option key={option.kode} value={option.kode}>
                              {option.nama}
                            </option>
                          ))}
                        </Select>
                      </div>
                      <div className="block mb-2">
                          <Label htmlFor="keterangan" value="Keterangan" />
                      </div>
                      <TextInput
                          id="Keterangan"
                          value={keterangan}
                          onChange={(e) => setKeterangan(e.target.value)}
                          placeholder="Keterangan"
                          type='text'
                      />
                  </div>
                  <div>
                  {cameraIsActive && showVideox ? (
                      <Camera
                        idealFacingMode={FACING_MODES.ENVIRONMENT}
                        imageType={IMAGE_TYPES.JPG}
                        onTakePhoto={(dataUri) => handleTakePhoto(dataUri)}
                      />
                    ) : (
                      <img src={capturedImage} alt="" />
                    )}
                  </div>
                  <Button style={{ fontFamily:'cursive' }} disabled={isButtonDisabledPhoto} onClick={toggleCamera} >{capturedImage ? 'Ubah Photo' : 'Photo'}</Button>
                  <div>
                    <Button onClick={simpanData} disabled={isButtonDisabledx} style={{ backgroundColor: 'rgb(22 147 43)', fontFamily:'cursive' }}>
                      Simpan Data
                    </Button>
                  </div>
              </form>
          </Card><br></br><br></br><br></br><br></br>
        </div>
        <BottomNav />
      </div>
    );
}

export default Onprogress;
